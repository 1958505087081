import { Factory, MessageService } from "@/services/_base";
import { Deferred } from "@/services/_base/Deferred";

export class ModalHelper {
    public static ShowModal(view, modalname: string = "modal", showHeader: boolean = false) {
        if (modalname == null)
            modalname = "modal";
        Factory.Get(MessageService).publishToChannel("show-" + modalname, view, null, null, showHeader);
    }

    public static ShowModalAsync(view, model = null, modalname: string = "modal"): Promise<any> {
        let deferred = new Deferred();
        Factory.Get(MessageService).publishToChannel("show-" + modalname, view, deferred, model);
        return deferred.promise;
    }

    public static HideModal(modalname: string = "modal") {
        Factory.Get(MessageService).publishToChannel("hide-" + modalname);
    }
}