import { baseRestService } from "@/services/_base";
import Config from "@/config";

class MailingListService extends baseRestService {

  constructor() {
    super();
    this.baseUrl = Config.appServerUrl;
  }

  async GetLists(): Promise<server.MailingList[]> {
    return await this.Get<server.MailingList[]>(`/mailing/lists`);
  }

  async SetList(model: server.MailingList): Promise<server.MailingList> {
    return await this.Post<server.MailingList>(`/mailing/list`, model);
  }

  async GetContacts(listid: string = null): Promise<server.Contact[]> {

    return await this.Get<server.Contact[]>(`/mailing/contacts`, { listid });
  }

  async SetContact(model: server.Contact): Promise<server.Contact> {
    return await this.Post<server.Contact>(`/mailing/contact`, model);
  }

  async AddContactToList(contactid: string, listid: string) {
    return await this.Post<void>(`/mailing/contact/${contactid}/list/${listid}`, {});
  }

  async RemoveContactFromList(contactid: string, listid: string) {
    return await this.Delete<void>(`/mailing/contact/${contactid}/list/${listid}`, {});
  }

  async DeleteContact(contactid: string) {
    return await this.Delete(`/mailing/contact/${contactid}`)
  }

  async DeleteList(listid: string) {
    return await this.Delete(`/mailing/list/${listid}`)
  }

  async GetTemplates(): Promise<{ id: string, name: string }[]> {
    return await this.Get<{ id: string, name: string }[]>(`/mailing/template`);
  }

  async Send(listId: string, templateId: string, contentUri: string, subject: string) {
    return await this.Post(`/mailing/send`, {
      listId, templateId, contentUri, subject
    });
  }

  async IsInMailing(): Promise<{exists: boolean}> {
    return await this.Get<{exists: boolean}>(`/mailing/existence-in-lists`);
  }
}

export const mailingListService = new MailingListService();