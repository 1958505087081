import { localizeFunction } from "@/filters/localize";
import { weatherForecastService } from "@/services";
import moment from "moment";

export class WeatherHelper {


  static createSerie(name, yAxes, color, unit) {

    return {
      data: null,
      yAxis: yAxes,
      color: color,
      name: localizeFunction(name, name),
      legend: { enabled: false },
      lineColor: color,
      tooltip: {
        pointFormatter: function () {
          var point = this;
          var pointValue = point.y;
          return '<span style="color:' + point.color + '">\u25CF</span> ' + point.series.name + ': <b>' + pointValue + unit + '</b><br/>';
        }
      },
      marker: { fillColor: 'white', symbol: "circle", lineWidth: 1, lineColor: color, enabled: false },
      states: { hover: { enabled: true } },
      type: "line",
      dashStyle: "Solid",
      zIndex: 1
    }
  }

  static createBarSerie(name, yAxes, color, unit) {

    return {
      data: null,
      yAxis: yAxes,
      color: color,
      name: localizeFunction(name, name),
      legend: { enabled: false },
      lineColor: color,
      tooltip: {
        pointFormatter: function () {
          var point = this;
          var pointValue = point.y;
          return '<span style="color:' + point.color + '">\u25CF</span> ' + point.series.name + ': <b>' + pointValue + unit + '</b><br/>';
        }
      },
      marker: { fillColor: 'white', symbol: "circle", lineWidth: 1, lineColor: color, enabled: false },
      states: { hover: { enabled: true } },
      type: "column",
      dashStyle: "Solid",
      zIndex: 1
    }
  }


  static createAreaSerie(name, yAxes, color, unit) {

    return {
      data: null,
      yAxis: yAxes,
      color: color,
      name: localizeFunction(name, name),
      legend: { enabled: false },
      lineColor: color,
      tooltip: {
        pointFormatter: function () {
          var point = this;
          var pointValue = point.y;
          return '<span style="color:' + point.color + '">\u25CF</span> ' + point.series.name + ': <b>' + pointValue + unit + '</b><br/>';
        }
      },
      marker: { fillColor: 'white', symbol: "circle", lineWidth: 1, lineColor: color, enabled: false },
      states: { hover: { enabled: true } },
      type: "area",
      dashStyle: "Solid",
      zIndex: 1
    }
  }

  static createScatteredSerie(name, yAxes, color, unit) {

    return {
      data: null,
      yAxis: yAxes,
      color: color,
      name: localizeFunction(name, name),
      legend: { enabled: false },
      tooltip: {
        pointFormatter: function () {
          var point = this;
          var pointValue = point.y;
          return '<span style="color:' + point.color + '">\u25CF</span> ' + point.series.name + ': <b>' + pointValue + unit + '</b><br/>';
        }
      },
      type: "scatter",
      zIndex: 1
    }
  }

  static createWindBarbSerie(name, yAxes, color, unit) {

    return {
      data: null,
      yAxis: yAxes,
      color: color,
      name: localizeFunction(name, name),
      legend: { enabled: false },
      lineColor: color,
      tooltip: {
        pointFormatter: function () {
          var point = this;
          var pointValue = point.y;
          return '<span style="color:' + point.color + '">\u25CF</span> ' + point.series.name + ': <b>' + pointValue + unit + '</b><br/>';
        }
      },
      marker: { fillColor: 'white', symbol: "circle", lineWidth: 1, lineColor: color, enabled: false },
      states: { hover: { enabled: true } },
      type: "windbarb",
      dashStyle: "Solid",
      zIndex: 1
    }
  }

  static createRangeSerie(name, yAxes, color, unit) {
    return {
      data: null,
      yAxis: yAxes,
      color: color,
      name: localizeFunction(name, name),
      legend: { enabled: true },
      lineColor: color,
      tooltip: {
        pointFormatter: function () {
          var point = this;
          var pointValue = point.low + " ~ " + point.high;
          return '<span style="color:' + point.color + '">\u25CF</span> ' + point.series.name + ': <b>' + pointValue + " " + unit + '</b><br/>';
        },
      },
      fillOpacity: 0,
      marker: { enabled: false, states: { hover: { enabled: false } }, },
      type: "arearange",
      dashStyle: "longdash",
      zIndex: 0,
      linkedTo: ':previous'
    }
  }

  static createYAxis(title, yAxesID, color, unit, opposite, position, height, max: number = null, min: number = null, offset: number = 30) {
    return {
      id: yAxesID, //yAxes
      title: { text: localizeFunction(title), style: { color: color }, offset: 30 },
      labels: {
        style: { color: color },
        align: opposite ? "right" : "left",
        formatter: function () { return this.value + unit },
      },
      offset: offset,
      opposite: opposite,
      showFirtsLabel: true,
      top: position, height: height,
      max: max,
      min: min
    }
  }

  // Restituisce un array di previsioni a partire da latitudine e longitudine
  static async getForecastData(latitude: number, longitude: number, day: number, limit?: number): Promise<Array<ForecastWeather>> {
    var result: Array<ForecastWeather> = [];
    var date = new Date();

    date.setDate(date.getDate() + day);

    var forecastData = await weatherForecastService.Forecasts(latitude, longitude, date);

    if (forecastData) {
      var forecasts = forecastData.forecasts;

      var minIndex = 0;
      var maxIndex = forecasts.length;

      // Nel caso di oggi (day = 0) prendo la prima previsione disponibile, altrimenti prendo quella di mezzo (se limit = 1)
      if (limit && limit == 1) {
        if (day == 0) {
          maxIndex = 1;
        } else if (day > 0) {
          minIndex = Math.floor(forecasts.length / 2);
          maxIndex = minIndex + 1;
        }
      }

      for (let i = minIndex; i < maxIndex; i++) {
        var item = forecasts[i];

        var forecast = {};
        var temperature = {};
        var temperatureRange = {};
        var precipitationAmount = {};
        var wind = {};
        var humidity = {};
        var pressureAtmospheric = {};
        var snow = {};

        forecast = {
          "date": moment(item.date).format("lll"),
          "condition": item.condition,
          "icon": item.icon.replace("http://", "https://"),
        };

        for (let j = 0; j < item.sensors.length; j++) {
          var sensorItem = item.sensors[j];

          if (sensorItem.key == 'temperature') {
            temperature = {
              "value": Math.round(sensorItem.value),
              "unit": item.units.temperature,
            };
          }

          if (sensorItem.key == 'temperaturerange') {
            temperatureRange = {
              "minValue": Math.round(sensorItem.value[0]),
              "maxValue": Math.round(sensorItem.value[1]),
              "unit": item.units.temperature,
            };
          }

          if (sensorItem.key == 'precipitationamount') {
            precipitationAmount = {
              "value": Math.round(sensorItem.value),
              "unit": item.units.precipitationamount,
            };
          }

          if (sensorItem.key == 'wind') {
            wind = {
              "direction": sensorItem.value.direction,
              "speedValue": sensorItem.value.speed,
              "speedUnit": item.units.windspeed,
              "degreesValue": sensorItem.value.degrees,
              "degreesUnit": item.units.winddegrees,
            };
          }

          if (sensorItem.key == 'humidity') {
            humidity = {
              "value": sensorItem.value,
              "unit": item.units.humidity,
            };
          }

          if (sensorItem.key == 'pressureatmospheric') {
            pressureAtmospheric = {
              "value": sensorItem.value,
              "unit": item.units.pressureatmospheric,
            };
          }

          if (sensorItem.key == 'snow') {
            snow = {
              "value": sensorItem.value,
              "unit": item.units.snow,
            };
          }
        }

        result.push({
          "forecast": forecast,
          "temperature": temperature,
          "temperatureRange": temperatureRange,
          "precipitationAmount": precipitationAmount,
          "wind": wind,
          "humidity": humidity,
          "pressureAtmospheric": pressureAtmospheric,
          "snow": snow,
        });
      }
    }

    return result;
  }
}

export enum ChartMode {
  YESTERDAY = 0, TODAY = 1, WEEK = 2, MONTH = 3, CUSTOM = 4
}

export class ForecastWeather {
  forecast: {};
  temperature: {};
  temperatureRange: {};
  precipitationAmount: {};
  wind: {};
  humidity: {};
  pressureAtmospheric: {};
  snow: {};
}
